import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Icon404 } from "@/components/common/Icons";
import { Button } from "antd";
import styled from "styled-components";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Pretendard } from "@/components/common/fonts";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    max-width: 648px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 60px;
    text-align: center;

    h1,
    p {
      font-weight: 400;
      margin: 0;
    }

    h1 {
      font-size: 32px;
      line-height: 42px;
      font-family: Pretendard.string;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      color: colors.$gray5;
    }

    .home-btn {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 180px;
      padding: 20px 24px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      background-color: #3075f6;
    }
  }
`;

function Page404() {
  const { t } = useTranslation("common");
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push('/dashboard')
    }, 3000);
  }, [router]);

  return (
    <Wrapper>
      <div className="content">
        <h1>{t("We lost this page")}</h1>
        <p>
          {t(
            "We searched high and low, but couldn't find what you're looking for."
          )}
          <br />
          {t("Let's find a better place for you to go.")}
        </p>
        <Link legacyBehavior href="/dashboard">
          <Button className="home-btn">{t("Back to Home")}</Button>
        </Link>
      </div>
      <Icon404 />
    </Wrapper>
  );
}

export default Page404;
